<template>
    <breadCrumb/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
export default {
    components: {
        breadCrumb
    },
    data(){
        return{}
    }
}
</script>